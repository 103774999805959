<h1 mat-dialog-title>Input token to access API</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Token</mat-label>
    <input
      matInput
      type="password"
      cdkFocusInitial
      [formControl]="token"
      autocomplete="off"
    />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button
    [disabled]="token.invalid"
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    (click)="closeDialog()"
  >
    Add
  </button>
</div>
