// This file is automatically generated. Don't edit.
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { subsToUrl } from './../subs-to-url.func';
import { FileDTO } from '../models/index';

@Injectable({
  providedIn: 'root',
})
export class GuideApiService {
  constructor(private http: HttpClient) {}

  getFileContent(file: string): Observable<FileDTO> {
    const url = subsToUrl('/api/guide/get-file-content/:file', { file });
    return this.http.get<FileDTO>(url);
  }
}
