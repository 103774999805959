<mat-card>
  <h1>About</h1>
  <p>Version: {{ version }}.</p>
  <p>
    This is a subjective view on DynamoDB single table design. If you follow the
    single table design, you can see the schemas of your tables in
    <a href="/tables">Tables</a> and the data browsing for selected table works
    with maximal experience.
  </p>
  <p>
    See the code in <a href="https://github.com/stenvala/dyndev">Github</a>. See
    the licensing for the utilization of the source code there.
  </p>
  <h2>License to continue using the Software</h2>
  <p>
    Perhaps you pulled this Software from
    <a href="https://hub.docker.com/repository/docker/stenvala/dyndev/general"
      >Docker hub</a
    >. Here are the conditions for you to continue using the Software.
  </p>
  <p>
    For all the companies making more revenue than <b>10 M USD</b> a year,
    please contact the author via e-mail (antti+dyndev@mail.mathcodingclub.com)
    before starting to use the software. Subscription is needed to continue
    using the Software.
  </p>
  <p>
    License for non-profit organizations, individuals and companies making less
    revenue than 10 M USD a year.
  </p>
  <p>Copyright 2022 Antti Stenvall</p>
  <p>
    Permission is hereby granted, free of charge, to use the Software, and to
    use the code snippets visible in the user interface of the Software subject
    to the following conditions:
  </p>
  <p>
    THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
    IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
    FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
    AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
    LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING
    FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS
    IN THE SOFTWARE.
  </p>
</mat-card>
