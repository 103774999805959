<h1 mat-dialog-title>Add category</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Name of category</mat-label>
    <input
      matInput
      type="text"
      cdkFocusInitial
      [formControl]="categoryNameFC"
      autocomplete="off"
    />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button
    mat-flat-button
    color="secondary"
    [mat-dialog-close]="false"
    (click)="closeDialog(false)"
  >
    Cancel
  </button>
  <button
    [disabled]="categoryNameFC.invalid"
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    (click)="closeDialog(true)"
  >
    Add
  </button>
</div>
