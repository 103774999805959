<ng-container *ngIf="isTableInitialized">
  <sample-app-toolbar></sample-app-toolbar>

  <mat-card>
    <h1>Sample app</h1>
    <p>
      This is an oversimplified featureless application for managing tasks
      utilizing DynamoDB that has STD. Start by creating categories and then add
      notes to categories.
    </p>
    <p>
      One important question arises immediately.
      <i
        >On the left hand menu, you might want to see count in each category and
        status. How would you implement that in DynamoDB?</i
      >
      No solution is given for this in the
      <a href="/guide/basics">design principles</a>, but there is a hint. Try to
      find it.
    </p>
  </mat-card>
</ng-container>
