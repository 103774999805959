<mat-card>
  <h1>Provisioning table in AWS</h1>
  <p>
    You provision your AWS infrastructure according to the principles of
    <i>Infrastructure as code</i>. With AWS the ultimate way to do this is with
    <a href="https://aws.amazon.com/cdk/">AWS Cloud Development Kit</a> (CDK).
    For example, a table with three GSIs, you provision with a code like the one
    below. Before you provision, make sure you understand how to encrypt the
    data at rest and how to control the access to the table.
  </p>

  <pre><code class="language-typescript">{{cdkCode}}</code></pre>
</mat-card>
