// This file is automatically generated. Don't edit.
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { subsToUrl } from './../subs-to-url.func';
import {
  CreateTaskCategoryDTO,
  CreateTaskDTO,
  StatusDTO,
  TaskCategoriesDTO,
  TaskCategoryDTO,
  TaskDTO,
  TasksDTO,
} from '../models/index';

@Injectable({
  providedIn: 'root',
})
export class SampleAppApiService {
  constructor(private http: HttpClient) {}

  createCategory(dto: CreateTaskCategoryDTO): Observable<TaskCategoryDTO> {
    const url = subsToUrl('/api/sample-app/create-category', {});
    return this.http.post<TaskCategoryDTO>(url, dto);
  }

  createTable(): Observable<StatusDTO> {
    const url = subsToUrl('/api/sample-app/create-table', {});
    return this.http.get<StatusDTO>(url);
  }

  createTask(categoryId: string, dto: CreateTaskDTO): Observable<TaskDTO> {
    const url = subsToUrl('/api/sample-app/category/:categoryId/task', {
      categoryId,
    });
    return this.http.post<TaskDTO>(url, dto);
  }

  editTask(taskId: string, dto: TaskDTO): Observable<TaskDTO> {
    const url = subsToUrl('/api/sample-app/task/:taskId', { taskId });
    return this.http.put<TaskDTO>(url, dto);
  }

  getCategories(): Observable<TaskCategoriesDTO> {
    const url = subsToUrl('/api/sample-app/categories', {});
    return this.http.get<TaskCategoriesDTO>(url);
  }

  getTask(taskId: string): Observable<TaskDTO> {
    const url = subsToUrl('/api/sample-app/task/:taskId', { taskId });
    return this.http.get<TaskDTO>(url);
  }

  getTasksOfCategory(categoryId: string): Observable<TasksDTO> {
    const url = subsToUrl('/api/sample-app/category/:categoryId/tasks', {
      categoryId,
    });
    return this.http.get<TasksDTO>(url);
  }

  getTasksOfStatus(status: string): Observable<TasksDTO> {
    const url = subsToUrl('/api/sample-app/status/:status/tasks', { status });
    return this.http.get<TasksDTO>(url);
  }

  removeTask(taskId: string): Observable<StatusDTO> {
    const url = subsToUrl('/api/sample-app/task/:taskId', { taskId });
    return this.http.delete<StatusDTO>(url);
  }
}
