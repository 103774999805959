<mat-expansion-panel
  class="margin-bottom"
  [expanded]="state.isQueryOpen"
  *ngIf="indices && indices.length > 0"
  (opened)="toggleExpansionPanel('isQueryOpen', true)"
  (closed)="toggleExpansionPanel('isQueryOpen', false)"
>
  <mat-expansion-panel-header>
    <mat-panel-title>Query</mat-panel-title>
  </mat-expansion-panel-header>
  <form [formGroup]="queryForm">
    <div>
      <mat-form-field appearance="fill" class="margin-right">
        <mat-label>Partition key</mat-label>
        <mat-select formControlName="selectedPK">
          <mat-option
            *ngFor="let i of indices"
            [value]="i.keySchema[0].attributeName"
          >
            {{ i.keySchema[0].attributeName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Value (exact)</mat-label>
        <input matInput type="text" formControlName="selectedPKValue" />
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="queryForm.controls['selectedPKValue'].setValue('')"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill" class="margin-right">
        <mat-label>Sort key condition</mat-label>
        <mat-select formControlName="selectedSK">
          <mat-option *ngFor="let i of matchOptions" [value]="i">
            {{ i }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="margin-right" appearance="fill">
        <mat-label>Value</mat-label>
        <input matInput type="text" formControlName="selectedSKValue" />
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="queryForm.controls['selectedSKValue'].setValue('')"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <button
        mat-stroked-button
        [disabled]="queryForm.invalid"
        (click)="query()"
      >
        Query
      </button>
    </div>
  </form>
</mat-expansion-panel>

<mat-expansion-panel
  class="margin-bottom"
  [expanded]="state.isScanOpen"
  (opened)="toggleExpansionPanel('isScanOpen', true)"
  (closed)="toggleExpansionPanel('isScanOpen', false)"
>
  <mat-expansion-panel-header>
    <mat-panel-title>Scan</mat-panel-title>
  </mat-expansion-panel-header>
  <div class="margin-bottom">
    <button mat-stroked-button (click)="scanAll()">All</button>
  </div>

  <form [formGroup]="scanForm">
    <div class="margin-bottom" *ngIf="types.length > 0">
      <mat-form-field appearance="fill" class="margin-right">
        <mat-label>Type</mat-label>
        <mat-select formControlName="selectedType">
          <mat-option *ngFor="let i of types" [value]="i">
            {{ i }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-stroked-button (click)="scanByType()">Scan by type</button>
    </div>
  </form>

  <form [formGroup]="scanForm">
    <div class="margin-bottom" *ngIf="types.length > 0">
      <mat-form-field appearance="fill" class="margin-right">
        <mat-label>Attribute</mat-label>
        <input
          type="text"
          matInput
          formControlName="selectedField"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let i of optionAttributes | async" [value]="i">
            {{ i }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="fill" class="margin-right">
        <mat-label>Condition</mat-label>
        <mat-select formControlName="selectedComparator">
          <mat-option *ngFor="let i of scanOptions" [value]="i">
            {{ i }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="margin-right" appearance="fill">
        <mat-label>Value</mat-label>
        <input matInput type="text" formControlName="selectedValue" />
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="queryForm.controls['selectedValue'].setValue('')"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <button mat-stroked-button (click)="scan()">Scan</button>
    </div>
  </form>
</mat-expansion-panel>

<mat-card *ngIf="rowData" class="view-results">
  <div class="results-title">Results</div>
  <div class="margin-bottom-top">
    <button mat-stroked-button (click)="exportToExcel()" class="margin-right">
      Export to Excel
    </button>
    <button
      mat-stroked-button
      (click)="loadMore()"
      class="margin-right"
      *ngIf="hasLastKey"
    >
      Load more
    </button>
    <ng-container *ngIf="searchCount > 1">
      <button
        mat-icon-button
        color="accent"
        [disabled]="currentSearch === 0"
        (click)="populateSearchResultsFromIndex(currentSearch - 1)"
      >
        <mat-icon fontSet="fa" fontIcon="fa-caret-left"> </mat-icon>
      </button>
      <span> {{ currentSearch + 1 }}/{{ searchCount }} </span>
      <button
        mat-icon-button
        color="accent"
        (click)="populateSearchResultsFromIndex(currentSearch + 1)"
        [disabled]="currentSearch + 1 === searchCount"
      >
        <mat-icon fontSet="fa" fontIcon="fa-caret-right"> </mat-icon>
      </button>
    </ng-container>
    <button mat-icon-button (click)="clearSearchHistory()" class="margin-right">
      <mat-icon fontSet="fa" fontIcon="fa-times"> </mat-icon>
    </button>
    <span>
      keyboard: c = copy, s = scan, q = query, p = previous, n = next, t = scan
      by type, v = view
    </span>
    <span> | </span>
    <span> showing {{ rowData.length }} rows </span>
  </div>
  <ag-grid-angular
    style="left: 0px; right: 0px; height: 450px"
    class="ag-theme-balham-dark"
    [rowData]="rowData"
    [columnDefs]="colDefs"
    (cellKeyPress)="onCellKeyPress($event)"
    (gridReady)="onGridReady($event)"
  >
  </ag-grid-angular>
</mat-card>
